const config = {
  //配置默认语言
  Language: "kr",
  // Language :'tc',
  //ApiUrl: "https://h0760.com",
  // ApiUrl: "http://192.168.8.104:8086",
  //ApiUrl: "http://192.168.8.155",
  //ApiUrl: "http://192.168.8.188",
  //ApiUrl: "http://34.124.252.21",
  //ApiUrl: "http://127.0.0.1",
  // ApiUrl: "https://nft.f837.com",
  ApiUrl: "https://wth93b.opendnft.com",
};
export default config;

