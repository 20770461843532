const tab = {
  home:'首页',
  find:'发现',
  position:'持仓',
  collect:'收藏',
  my:'我的'
}
const index = {
  welcome:'欢迎',
  TNkkrich:'TNkkrich',
  notice:'NFT技術的影響力 全球數字資產政策 NFT新聞事件 NFT交易訂單類型與步驟',
  PreSale: '预售中',
  TransactionList: '交易列表',
  num:'数量',
  minPrice:'最低回购价',
  nftDetail:'NFT详情',
  Countdown:'倒计时',
  nftInfo:'NFT信息',
  publisherInfo:'发布方信息',
  features:'作品特性',
  desc:'作品描述',
  prePrice: '预售价',
  RepurchasePrice:'回购价格',
  PurchaseType:'采购类型',
  oerderNow:'立即下单',
  myCollect: '我的收藏',
  myCollect1: '我的碎片',
  Synthesis: '合成',
  hold: '持有',
  Introduction:'介绍',
  ViewTheAuthor:'查看该作者'
}

const find = {
  search:'搜索您要的精彩',
  myCollect: '我的收藏',
  myCollect_desc:'碎片化顶级NFT艺术展览尽在DNFT',
  NFTsp: 'DNFT碎片',
  NFTsp_desc: '将您持有的NFT分布成碎片',
  BecomeAmerchant: '成为发行方',
  BecomeAmerchant_desc:'成为发行方您可以发行属于您自己的NFT',
  Announcement_headlines: '公告头条',
  About_NFT: '关于NFT',
  Creation_Center: '创作中心',
  Synthesis_center: '合成中心',
  issure:'发行机构'
}

const position = {
  Mypositions:'我的持仓',
  PositionMarketValue: '持仓市值(USDT)',
  TodayProfitAndLoss: '今日盈亏',
  TotalProfitAndLoss: '总盈亏',
  HoldingCost: '持仓成本',
  Number_of_fragments: '碎片数量',
  PledgeShards: '质押碎片',
  PledgeIncome: '质押收入',
  DNFTRoyaltiesTrade:'DNFT 版税交易',
  QuickBuy: '一键买入',
  QuickSell: '一键卖出',
  History:'历史',
  name:'名称',
  price:'即时价',
  Market:'市值',
  Pledge: '质押',
  TNFT: 'DNFT',
  BuyingPrice: '买入价格',
  SellingPrice: '卖出价格',
  EstimatedBuybackPrice:'预计回购价格',
  RepurchasePriceRatio:'回购价格比例',
  BuyQuantity:'买入数量',
  SellQuantity:'卖出数量',
  placeholder1: '请输入买入数量',
  placeholder2: '请输入卖出数量',
  submit:'提交',
  OTCwallet:'OTC钱包',
  SellToOTCbalance:'卖出到OTC余额',
  trxnumber:'TRX数量',
  onSale:'挂单',
  sellingScope:'卖出范围',
  hangingOrderPrice:'挂单价格',
  surplus:'剩余',
  withdraw:'撤回',
  completed:'已完成',
  withdrawn:'已撤回',
  sellOut:'卖出',
  purchase:'买入',
  comfirmTips:'确认撤销吗？',
  confirm:'确认',
  cancel:'取消',
  unlimited:'不限',
  acquisition:'收购'
}

const order ={
  orderHistory:'历史记录',
  all: '全部',
  inProgress: '进行中',
  expired: '已失效',
  completed: '已完成',
  orderID:'订单号',
  Payable:'需支付',
  buy:'买入',
  viewNow:'立即查看',
  PaymentDetails:'支付详情',
  PurchaseType:'购买类型',
  OrderTime:'购买时间',
  State:'状态'
}

const login = {
  TNFT_account_login:'DNFT account login',
  phone:'手机号码',
  mail:'邮箱',
  pwd:'密码',
  placeholder1: '请输入手机号码',//'Please enter your phone number',
  placeholder2: '请输入邮箱',//'Please enter your email',
  placeholder3: '请输入密码',//'Please enter a password',
  placeholder4: '请输入验证码',//'Please enter a password',
  placeholder5: '请输入邀请码',//Please enter the recommendation code
  forgotPwd: '忘记密码？',
  forgotPwd1: '忘记密码',
  Reset_password :'重置密码',
  login:'登录',
  Dont_have_an_account_yet: '还没账户？',
  Register_now: '立即注册',
  Register:'注册',
  VerificationCode:'验证码',
  ConfirmPassword:'确认密码',
  getCode:'验证码',
  send:'已发送',
  Referral_Code:'邀请码',
  by:'点击注册表示我已阅读并同意',
  User_Agreement:'用户协议',
  submit: '确认'
}

const share = {
  My_invitation_code:'我的邀请码',
  desc:'扫描播放DNFT，0交易版税，更多推荐版税',
  save:'保存二维码'
}

const my = {
  Merchant:'商家',
  OTCwallet:'OTC钱包',
  myCollect: '我的收藏',
  myNFT:'我的NFT',
  Fragments: '碎片',
  OTCbalance: 'OTC余额',
  Exchange:'版税兑换',
  Transfer_to_NFT:'转入NFT',
  Staking_TNFT: '质押NFT',
  Account_binding: '账户绑定',
  Invite_members: '邀请会员',
  Contact_Customer_Service: '联系客服',
  sign_out:'退出登录',

  Apply_to_be_a_merchant:'申请成为商家',
  Company_Name:'机构名称',
  p_e_Company_Name:'请输入机构名称',
  Country: '国家',
  p_e_Country: '请输入国家',
  Introduction: '介绍',
  p_e_Introduction: '请输入介绍',
  Business_license:'营业执照',
  Front_side_of_legal_person_certificate:'法人证书正面',
  The_reverse_side_of_the_legal_person_certificate:'法人证书反面',

  Apply_to_be_a_auth:'申请实名认证',
  shiming_auth:'实名认证保证金',
  auth_realname:'姓名',
  auth_idcard:'身份证号',
  bank_name:'银行名称',
  bank_account:'银行账户名',
  bank_card:'银行账号',
  Front_side_of_certificate:'身份证正面',
  The_reverse_side_of_certificate:'身份证反面',
  p_auth_realname: '请输入姓名',
  p_auth_idcard: '请输入身份证号',
  p_bank_name:'请输入银行名称',
  p_bank_account:'请输入银行账户名',
  p_bank_card:'请输入银行账号',

  Submit:'提交',
  reSubmit:'重新提交',

  Edit_information: '编辑信息',
  avatar: '头像',
  Nickname: '真实姓名',
  Account: '账户',
  Referral_code: '推荐码',
  Credit_score: '信用分',
  SecuritySettings: '安全设置',
  Transaction_password: '交易密码',
  New_Transaction_password: '新交易密码',
  Login_password: '登录密码',
  New_Login_password: '新登录密码',
  ConfirmPassword: '确认密码',
  p_e_Nickname: '请输入姓名',
  p_e_Password: '请输入密码',

  Redeem_NFT: '兑换NFT',
  Synthesis: '合成',
  price: '价格',
  Number_of_holdings:'持股数量',
  Price_trend: '价格趋势',
  Buy: '买',
  Sell: '卖',
  OTCtrading:'OTC交易',
  OTC_quick_transaction:'场外快速交易',
  OTC_desc:'快速交易使您能够以当前场外交易市场的最佳价格完成交易',
  I_want_to_pay: '我想支付',
  I_will_receive: '我会收到',
  Proposed_price: '参考价格',
  How_to_use_OTC:'如何使用_OTC',
  OTCdesc1: '1.在购买数字货币之前，您首先需要注册一个OTC账户。通过提供的电子邮件地址或手机上收到的验证码验证您的帐户，或直接使用DNFT帐户注册。',
  OTCdesc2: '2.为了确保交易的安全性和合规性，请提供身份证件进行身份验证。平台将审查您提供的身份信息。这个过程可能需要一些时间，所以请耐心等待。',
  OTCdesc3: '3.一旦完成账户注册并通过身份验证，您就可以开始购买数字货币。进入购买页面，然后选择要购买的数字货币。输入采购数量并确认交易。在确认交易之前，请务必检查订单的所有详细信息。一旦您的订单匹配并完成付款，请等待数字货币转移到您的OTC账户。',

  OTCrecords:'OTC记录',
  Unit_price:'单价',
  Quantity:'数量',
  Approved:'经核准',
  Cancelled:'取消',
  NFT_transfer_record:'NFT转入记录',

  NFT_transfer_in:'NFT转入',
  Category:'类别',
  address: '地址',
  p_e_address: '请输入地址',
  NFTname:'NFT名称',
  Features:'特征',
  Description:'描述',
  p_e_name: '请输入名称',
  p_e_Features: '请输入特征',
  p_e_Description: '请输入描述',
  Upload_NFT_file:'上传NFT文件',
  Upload_transfer_certificate:'上传转让证明',
  copy_success:'复制成功',
  Confirm:'确认',
  Pledge: '质押',
  Hold: '持有',
  Pre_sale_price:"预售价格",
  Time_day:'时间(天)',
  Pledge_quantity:'质押数量',
  p_e_quantity:'请输入数量',
  Quantity_Available:'可用数量',
  all:'全部',
  Fragment_reward_days:'碎片奖励(天)',
  Remark:'备注',
  Pledge_time:'质押时间',
  Interest_accrual_time: '计息时间',
  Redemption_time: '赎回时间',
  Award:'奖励',
  Creation_time:'创建时间',
  End_time:'结束时间',
  Unpledge:'解除质押',
  Team_list:'团队列表',
  account:'账户',
  zctime:'注册时间',
  Royalty_Details:'版税明细',
  income: '收入',
  spending: '支出',
  Bind_account:'绑定账户',
  Please_enter_the_transaction_password:'请输入交易密码',
  noSart:'暂未对普通会员开放',
  search:'搜索',
  latest: '最新',
  hottest: '最热',
  p_e_search:'请输入关键字',
  Application_Notes:'申请须知',
  issuer_desc:'申请成为NFT发行方，需要您有相应的区块链合法资质，详情请联系官方客服或邮箱',
  start:'开始',

  Make_NFT_fragments:'制作NFT碎片',
  NFTfile:'NFT文件',
  Purchase_limit: '限购',
  Purchase_limit_desc: '每人限购份额',
  Currency: '货币',
  Buyback_price: '回购价格',
  countDown: '倒计时(小时)',
  Transaction_object_ID: '交易对象ID',
  Click_to_select_NFT_file: '选择NFT文件',
  Click_to_select_Currency: '选择货币',
  Click_to_select_countDown: '选择倒计时时间',
  p_e_price: '请输入价格',
  create_now:'立即创建',
  Release_record:'发布记录',
  feeDesc:'提款手续费',
  feeDesc2:'将被扣除手续费',
}

const nft ={
  collect:"收藏品",
  transfer:"转移NFT",
  pledgeRecord:"碎片质押记录",
  hangout:'挂单',
  buytitle:'我要买',
  buy:'购买',
  sell:'出售',
  selltitle:'我要卖',
  listcount:'挂单数量',
  owner:'拥有者',
  between:'范围',
  remains:'剩余',
  hangoutlabel:['当前持有','NFT总份数','类型','预售价格',
    '价格','数量','每次限购','最高','卖出','买入'],
  hangplaceholder:['请输入挂单价格','请输入数量',
  '请输入限购数量','请输入最高份数'],
  holdnumber:'持有数量',
  available_number:'可用数量',
  hangoutType:["普通订单","福利订单","指定用户ID","请输入指定用户ID"]
}

const otc ={
  orderID:"订单号",
  amount:"金额",
  addtime:"时间",
  detailtitle:'交易信息',
  logintips:[
      '您的信息受256位SGC保护',
      '使用加密进行安全传输'
  ],
  detail:['价格','交易数量','支付金额','订单号','时间','请联系客服']
}

const onekey ={
  royalties:'版税',
  hold:'持有数量',
  all:'全部',
  selltips:[
    'NFT版税是一种简单且容易的方式，从您的辛勤工作中获利。',
    'NFT版税为艺术家、游戏开发者和内容创作者提供了一个前所未有的机会，通过二次销售获利。',
    '您可以通过DNFT版税交易快线买卖您的版税，从中获利。'
  ],
}

const order2 ={
  presale:'预售',
  otc:'OTC余额',
  pay:'支付',
  c2c:'C2C转账',
  waitconfirm:'待审核',
  pass:'已通过',
  c2ctip:'C2C需要你手工转账',
  status:['','等待支付','等待确认','已完成',"已失败"],
  confirm:['确认下单','预售进行中,请下单完成后在规定时间内支付给商家'],
  c2cpay:['支持货币','地址','支付金额','上传凭证','付款凭证'],
  position:['持仓数量','请先完成指定额度交易'],
  otctip:'OTC-pay将使用你的余额支付,请确认',
  missingfund:'交易密码缺失',
  setfundtip:'你还没有设置交易密码，请先设置交易密码'
}

export {
  tab,
  index,
  find,
  position,
  order,
  login,
  share,
  my,
  nft,
  otc,
  onekey,
  order2
}
